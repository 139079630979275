<template>
  <div id="pptlocal">
    <div class="title">*{{ names }}资源上传</div>
    <div class="main" v-if="filetype != '5'">
      <label for="name" id="names">
        <p>资源名称<span>*</span></p>
        <el-input
          v-model="name"
          id="names"
          placeholder="请输入资源名称"
        ></el-input>
      </label>

      <label for="imgs" id="imgs">
        <p v-if="filetype == '9'">上传图片<span style="color: #000">*</span></p>
        <p v-else>上传图片<span>*</span></p>

        <el-upload
          class="avatar-uploader"
          accept=".jpg, .jpeg, .png"
          action="false"
          multiple
          :show-file-list="false"
          :http-request="upload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </label>
      <div class="sinfo">建议上传尺寸：600*450的jpg或png格式图片</div>

      <el-upload
        v-if="filetype != '9'"
        class="upload-demo"
        :accept="accept"
        drag
        action="false"
        show-file-list="true"
        list-type="text"
        :http-request="uploadppt"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{ files }}</div>
        <div class="el-upload__tip" slot="tip">
          建议上传：大小35M以内 {{ accept }}格式的资源文件
        </div>
      </el-upload>

      <div class="addbtn" @click="local">确认上传</div>
    </div>
    <div class="main" v-if="filetype == 5">敬请期待</div>
    <!-- <el-button type="primary" @click="openFullScreen2"> 服务方式 </el-button> -->
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      name: "",
      imageUrl: "",
      file: "",
      names: "",
      uploadthing: "", // 上传接口
      accept: "", // 上传文件限制
      filetype: "",
      files: "请选择文件",
      parent: "",
      fullscreenLoading: false,
      loading: "",
    };
  },
  mounted() {
    util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
      console.log(res);
      if (res.data.status == 1) {
        // this.files = res.data.file_name + "(点击重新上传文件)";
        this.name = res.data.file_name;
        this.imageUrl = res.data.cover;
      }
    });
    this.filetype = this.$route.query.filetype;
    switch (this.$route.query.filetype) {
      case "1":
        this.names = "PPT";
        this.accept = ".ppt, .pptx";
        this.uploadthing = "upload_ppt";
        break;
      case "2":
        this.names = "文档";
        this.accept = ".doc,.docx,.pdf,.xls,.xlsx";
        this.uploadthing = "upload_doc";
        break;
      case "3":
        this.names = "视频";
        this.accept = ".mp4";
        this.uploadthing = "upload_video";
        break;
      case "4":
        this.names = "音频";
        this.accept = ".mp3";
        this.uploadthing = "upload_mp3";
        break;
      case "5":
        this.names = "图文";
        // this.accept = ".doc, .docx";
        // this.uploadthing = "upload_doc";
        break;
      case "7":
        this.names = "模型";
        this.accept = ".zip, .rar";
        this.uploadthing = "upload_model";
        break;
      default:
        this.names = "实景";
      // this.accept = ".doc, .docx";
      // this.uploadthing = "upload_doc";
    }
  },
  methods: {
    local() {
      if (this.$route.query.filetype == 9) {
        util.post(
          "/catalogFileEdit",
          {
            file_name: this.name,
            file_path: this.imageUrl,
            cover: this.imageUrl,
            id: this.$route.query.dataid,
          },
          (res) => {
            if (res.code == 1) {
              this.$router.push({
                path: "/main/addscene",
                query: { csid: this.$route.query.csid },
              });
            }
          }
        );
      } else {
        util.post(
          "/catalogFileEdit",
          {
            file_name: this.name,
            file_path: this.file,
            cover: this.imageUrl,
            id: this.$route.query.dataid,
          },
          (res) => {
            if (res.code == 1) {
              this.$router.push({
                path: "/main/addscene",
                query: { csid: this.$route.query.csid },
              });
            }
          }
        );
      }
    },
    upload(e) {
      let formData = new FormData();
      formData.append("file", e.file);
      console.log(e.file);
      util.post_file("/upload_img", formData, (res) => {
        this.imageUrl = res.data;
        console.log(res);
      });
    },
    uploadppt(e) {
      console.log(e);
      this.files = "正在上传";
      this.openFullScreen2();
      let formData = new FormData();
      formData.append("file", e.file);
      util.post_file("/" + this.uploadthing, formData, (res) => {
        console.log(res);

        this.files = "上传成功";
        this.loading.close();
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.file = res.data;
      });
    },
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: "正在上传...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // setTimeout(() => {
      //   loading.close();
      // }, 2000);
    },
  },
};
</script>
<style lang="less" scoped>
#pptlocal {
  padding: 35px 44px 0;
  background-color: #fff;
  height: 100%;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #131313;
  }
  .main {
    margin-left: 26px;
    margin-top: 40px;
    #names {
      margin-bottom: 37px;
    }
    .sinfo {
      font-size: 14px;
      font-weight: 400;
      color: #949595;
      margin-left: (27px + 88px);
      margin-top: 20px;
    }
    label {
      display: flex;
      p {
        width: 88/96in;
        text-align: right;
        margin-right: 28px;
        font-size: 16px;
        color: #131313;
        span {
          color: #f00;
        }
      }
    }
    .el-input {
      width: 350/96in;
      height: 40/96in;
      line-height: 40/96in;
    }
    /deep/.el-input__inner {
      border: 1px solid #949595;
      padding-left: 15px;
    }
    .addbtn {
      width: 100/96in;
      height: 36/96in;
      background: #1c5eff;
      border-radius: 5/96in;
      line-height: 36/96in;
      color: #fff;
      font-size: 14/96in;
      text-align: center;
      margin-left: 693/96in;
      margin-top: 80/96in;
      cursor: pointer;
    }
  }
}
.active {
  background-color: #1c5eff;
}
/deep/.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid #949595;
  border-radius: 5px;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28/96in;
  color: #8c939d;
  width: 190/96in;
  height: 110/96in;
  line-height: 110/96in;
  // border: 1px solid #949595;
  // border-radius: 5px;
  text-align: center;
}
/deep/.avatar {
  width: 190/96in;
  height: 110/96in;
  display: block;
}

/deep/.el-upload-dragger {
  background-color: #fff;
  border: 0.01rem dashed #d9d9d9;
  border-radius: 0.06rem;
  box-sizing: border-box;
  width: 190/96in;
  height: 110/96in;
  text-align: center;
  position: relative;
  overflow: hidden;
}
/deep/.el-upload-dragger .el-icon-upload {
  font-size: 31/96in;
  color: #c0c4cc;
  margin: 18px 0 0;
  line-height: 50/96in;
}
/deep/.upload-demo {
  margin-left: 28/96in + 88/96in;
  margin-top: 38px;
  font-size: 14px;
  .el-upload__text {
    color: #949595;
  }
  .el-upload__tip {
    font-size: 14px;
    font-weight: 400;
    color: #949595;
    margin-bottom: 67px;
  }
}
/deep/.el-upload-list .el-upload-list--text {
  width: 684/96in;
}
</style>